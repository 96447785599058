<template>
  <div>
    <div class="newCon container">
      <b-row class="space">
        <b-col v-for="(posts, index) in posts" :key="index" lg='4' md='6' cols='12' class="storyBlock">
          <div class="art-images">
            <b-img
              class="newsImg"
              :src="posts.better_featured_image.source_url.replace('https://assets.asiantour.com','https://assets.asiantour.com/toyota-tour')"
              fluid
              alt="Responsive image"
            />
          </div>
          <h5 class="news-archive-bottom-title">
            {{ posts.title.rendered }}
          </h5>

          <b-nav-item :to="'/' + posts.slug" class="Readstory">
            Read Story
          </b-nav-item>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";

export default {
  name: "posts",
  props: ["config"],
  data() {
    return {
      posts: [],
    };
  },
      created() {
    const json = "wp/v2/posts";
    apiCall 
      .wpInfo(json)
      .then(({ data }) => {
        this.posts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.ReadStory:hover {
  background-color: #3E3E3E;
}
.art-images > img {
  max-width: fit-content;
  width: 100%;
}
li.nav-item.Readstory > a:hover {
  background-color: #B91B29;
}
li.nav-item.Readstory > a {
}
::v-deep h5.news-archive-bottom-title {
  color: #3E3E3E;
  font-size: 13pt;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  vertical-align: middle;
  line-height: 1.5;
  padding-bottom: 11px;
  font-weight: 400;
  margin-top: 1em;
}
::v-deep .nav-link {
  color: white;
  background-color: #EB0A1E;
  /* width: 40%; */
  text-align: center;
}
::v-deep .nav-link:hover {
  color: white;
  background-color: #B91B29;
  /* width: 40%; */
  text-align: center;
}
.story {
  padding: 1%;
}
.side {
  width: 130%;
}
.imgSize {
  overflow: hidden;
  max-height: 212px;
}
.newsImgSide {
  width: 86% !important;
  margin: 0;
  height: auto !important;
}
.newCon {
  padding: 3%;
  padding-left: 0px;
}

.art-images {
  overflow: hidden;
  /* height: 350px; */
}
.space {
  padding-bottom: 2%;
  /* border-bottom: 1px solid lightgrey; */
  padding-top: 2%;
}
@media only screen and (max-width: 480px) {
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    width: 35%;
    text-align: center;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
}
@media only screen and (max-width: 768px) {
  .storyBlock.col-md-6.col-lg-4.col-12 {
    margin-bottom: 30px;
  }
}
</style>
